import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceComponent } from './reference.component';
import { NbAccordionModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from '../error/error.module';
import { ButtonModule } from '../buttons/buttons.module';
import { MarkdownEditorModule } from '../markdown-editor/markdown-editor.module';
import { ValidationErrorsModule } from '../validation-errors/validation-errors.module';

@NgModule({
    declarations: [ReferenceComponent],
    imports: [
        NbCardModule,
        NbAccordionModule,
        NbIconModule,
        ReactiveFormsModule,
        NbInputModule,
        ErrorModule,
        ButtonModule,
        CommonModule,
        MarkdownEditorModule,
        ValidationErrorsModule,
    ],
    exports: [ReferenceComponent],
})
export class ReferenceModule {}

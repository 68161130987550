import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDialogModule,
    NbIconModule,
    NbLayoutModule,
    NbListModule,
    NbSidebarModule,
} from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { TasksComponent } from './tasks.component';
import { ErrorModule } from '../../components/error/error.module';
import { FormsModule } from '@angular/forms';
import { SearchSelectModule } from '../../components/search/search.module';

@NgModule({
    declarations: [TasksComponent],
    imports: [
        CommonModule,
        RouterModule, // RouterModule.forRoot(routes, { useHash: true }), if this is your app.module
        NbLayoutModule,
        NbSidebarModule, // NbSidebarModule.forRoot(), //if this is your app.module
        NbButtonModule,
        NbCardModule,
        Angular2SmartTableModule,
        NbCheckboxModule,
        NbListModule,
        NbDialogModule,
        ErrorModule,
        NbIconModule,
        FormsModule,
        SearchSelectModule,
    ],
})
export class TasksModule {}

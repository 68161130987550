import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbOptionModule,
    NbProgressBarModule,
    NbSelectModule,
} from '@nebular/theme';
import { DistelTextInputModule } from '../distel-text-input/distel-text-input.module';
import { TaskSelectComponent } from './task-select.component';
import { FormsModule } from '@angular/forms';
import { Angular2SmartTableModule } from 'angular2-smart-table';

@NgModule({
    declarations: [TaskSelectComponent],
    imports: [
        CommonModule,
        NbSelectModule,
        NbOptionModule,
        NbInputModule,
        FormsModule,
        NbCardModule,
        DistelTextInputModule,
        NbIconModule,
        NbButtonModule,
        NbProgressBarModule,
        NbAutocompleteModule,
        Angular2SmartTableModule,
        NgOptimizedImage,
    ],
})
export class TaskSelectModule {}

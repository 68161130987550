import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrorsComponent } from './validation-errors.component';
import { ValidationErrorMsgComponent } from './validation-error-msg/validation-error-msg.component';

@NgModule({
    declarations: [ValidationErrorsComponent, ValidationErrorMsgComponent],
    imports: [CommonModule],
    exports: [ValidationErrorsComponent],
})
export class ValidationErrorsModule {}

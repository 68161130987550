import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatesComponent } from './states.component';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import {
    NbAccordionModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbSearchModule,
    NbSidebarModule,
    NbTagModule,
} from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { MutateStateModule } from './mutate-state/mutate-state.module';
import { DescriptionBlockListModule } from './components/description-block-list/description-block-list.module';
import { ErrorModule } from '../../components/error/error.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchSelectModule } from '../../components/search/search.module';
import { StateCategoryListComponent } from './state-category-list/state-category-list.component';
import { ImportExportStatesComponent } from './components/import-export-states/import-export-states.component';
import { ImportStatesConfirmationComponent } from './components/import-export-states/import-export-confirmation-dialog/import-states-confirmation.component';
import { ImportExportStatesModule } from './components/import-export-states/import-export-states.module';
import { MarkdownEditorModule } from '../../components/markdown-editor/markdown-editor.module';
import { ValidationErrorsModule } from '../../components/validation-errors/validation-errors.module';
import { StateFilterModule } from './components/state-filter/state-filter.module';

@NgModule({
    declarations: [StatesComponent, StateCategoryListComponent],
    imports: [
        CommonModule,
        RouterModule, // RouterModule.forRoot(routes, { useHash: true }), if this is your app.module
        NbLayoutModule,
        NbSidebarModule, // NbSidebarModule.forRoot(), //if this is your app.module
        NbButtonModule,
        NbCardModule,
        Angular2SmartTableModule,
        NbCheckboxModule,
        NbListModule,
        MutateStateModule,
        NbTagModule,
        DescriptionBlockListModule,
        ErrorModule,
        NbSearchModule,
        NbIconModule,
        FormsModule,
        SearchSelectModule,
        ImportExportStatesModule,
        MarkdownEditorModule,
        NbAccordionModule,
        NbInputModule,
        ValidationErrorsModule,
        ReactiveFormsModule,
        StateFilterModule,
    ],
    exports: [StatesComponent, ImportExportStatesComponent, ImportStatesConfirmationComponent],
})
export class StatesModule {}

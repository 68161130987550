import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchSelectModule } from '../search-select/search-select.module';
import { ProjectGroupSelectComponent } from './project-group-select.component';

@NgModule({
    declarations: [ProjectGroupSelectComponent],
    imports: [SearchSelectModule, CommonModule],
})
export class ProjectGroupSelectModule {}

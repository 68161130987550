import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    NbAccordionModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    NbTagModule,
} from '@nebular/theme';
import { StateInputComponent } from './state-input.component';
import { StateSummaryModule } from '../state-summary/state-summary.module';
import { ButtonModule } from '../buttons/buttons.module';
import { ValidationErrorsModule } from '../validation-errors/validation-errors.module';
import { StateDependencyCardComponent } from './state-dependency-card/state-dependency-card.component';

@NgModule({
    declarations: [StateInputComponent, StateDependencyCardComponent],
    exports: [StateInputComponent, StateDependencyCardComponent],
    imports: [
        CommonModule,
        NbAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        NbInputModule,
        NbSelectModule,
        NbTagModule,
        NbButtonModule,
        NbIconModule,
        NbCheckboxModule,
        StateSummaryModule,
        NbCardModule,
        ButtonModule,
        NbAccordionModule,
        ValidationErrorsModule,
    ],
})
export class StateInputModule {}

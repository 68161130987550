import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { NbButtonModule, NbCardModule, NbLayoutModule } from '@nebular/theme';
import { ButtonModule } from '../buttons/buttons.module';

@NgModule({
    declarations: [ConfirmDialogComponent],
    imports: [CommonModule, NbButtonModule, NbCardModule, ButtonModule, NbLayoutModule],
    exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}

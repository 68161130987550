import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
    NbAccordionModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbOptionModule,
    NbProgressBarModule,
    NbSelectModule,
    NbSpinnerModule,
} from '@nebular/theme';
import { TaskMutateComponent } from './task-mutate.component';
import { DistelTextInputModule } from '../distel-text-input/distel-text-input.module';
import { StateInputModule } from '../state-input/state-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { XapiCourseZipFileModule } from './xapi-course-zip-file/xapi-course-zip-file.module';
import { ButtonModule } from '../buttons/buttons.module';
import { CheckItemsComponent } from './check-item/check-items.component';
import { DragulaModule } from 'ng2-dragula';
import { MarkdownEditorModule } from '../markdown-editor/markdown-editor.module';
import { SearchSelectModule } from '../search/search.module';
import { ReferenceModule } from '../reference/reference.module';
import { ValidationErrorsModule } from '../validation-errors/validation-errors.module';
import { StateDependencyCardComponent } from '../state-input/state-dependency-card/state-dependency-card.component';

@NgModule({
    declarations: [TaskMutateComponent, CheckItemsComponent],
    imports: [
        StateInputModule,
        CommonModule,
        NbSelectModule,
        NbOptionModule,
        NbCardModule,
        DistelTextInputModule,
        NbIconModule,
        NbButtonModule,
        NbProgressBarModule,
        ReactiveFormsModule,
        NbInputModule,
        XapiCourseZipFileModule,
        ButtonModule,
        NbAccordionModule,
        DragulaModule,
        NbButtonGroupModule,
        NgOptimizedImage,
        SearchSelectModule,
        MarkdownEditorModule,
        ReferenceModule,
        NbSpinnerModule,
        ValidationErrorsModule,
    ],
    exports: [StateDependencyCardComponent],
})
export class TaskMutateModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NbFormFieldModule, NbInputModule } from '@nebular/theme';
import { FocusModule } from 'src/app/directive/focus.module';
import { DistelTextInputComponent } from './distel-text-input.component';

@NgModule({
    declarations: [DistelTextInputComponent],
    imports: [CommonModule, NbInputModule, FormsModule, NbFormFieldModule, FocusModule],
    exports: [DistelTextInputComponent],
})
export class DistelTextInputModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbListModule,
    NbRadioModule,
    NbSpinnerModule,
    NbTabsetModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportExportStatesComponent } from './import-export-states.component';
import { ImportStatesConfirmationComponent } from './import-export-confirmation-dialog/import-states-confirmation.component';

@NgModule({
    declarations: [ImportExportStatesComponent, ImportStatesConfirmationComponent],
    imports: [
        CommonModule,
        NbRadioModule,
        FormsModule,
        ReactiveFormsModule,
        NbInputModule,
        NbTabsetModule,
        NbSpinnerModule,
        NbButtonModule,
        NbCardModule,
        NbListModule,
    ],
    exports: [ImportExportStatesComponent, ImportStatesConfirmationComponent],
})
export class ImportExportStatesModule {}

import { NgModule } from '@angular/core';
import { ProjectsComponent } from './projects.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbCardModule, NbIconModule, NbTagModule } from '@nebular/theme';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { ErrorModule } from '../../components/error/error.module';
import { SearchSelectModule } from '../../components/search/search.module';
import { SmartTableInfoViewCellComponent } from '../../components/smart-table-view-cells/smart-table-info-view-cell/smart-table-info-view-cell.component';
import { SmartTableStatusViewCellComponent } from '../../components/smart-table-view-cells/smart-table-status-view-cell/smart-table-status-view-cell.component';
import { SmartTableTaskTypeViewCellComponent } from '../../components/smart-table-view-cells/smart-table-task-type-view-cell/smart-table-task-type-view-cell.component';

@NgModule({
    declarations: [
        ProjectsComponent,
        SmartTableInfoViewCellComponent,
        SmartTableStatusViewCellComponent,
        SmartTableTaskTypeViewCellComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        NbIconModule,
        Angular2SmartTableModule,
        ErrorModule,
        NbButtonModule,
        NbTagModule,
        NbCardModule,
        SearchSelectModule,
    ],
    exports: [SmartTableStatusViewCellComponent],
})
export class ProjectsModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidUntilComponent } from './valid-until.component';
import { NbAutocompleteModule, NbInputModule, NbRadioModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorsModule } from '../../../../components/validation-errors/validation-errors.module';

@NgModule({
    declarations: [ValidUntilComponent],
    imports: [
        CommonModule,
        NbRadioModule,
        FormsModule,
        ReactiveFormsModule,
        NbAutocompleteModule,
        NbInputModule,
        ValidationErrorsModule,
    ],
    exports: [ValidUntilComponent],
})
export class ValidUntilModule {}

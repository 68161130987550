import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    NbAccordionModule,
    NbAutocompleteModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    NbTagModule,
} from '@nebular/theme';
import { FilterCriteriaComponent } from './filter-criteria.component';
import { StateSummaryModule } from '../state-summary/state-summary.module';
import { FilterCriteriaListComponent } from './filter-criteria-list/filter-criteria-list.component';
import { ButtonModule } from '../buttons/buttons.module';
import { TaskMutateModule } from '../task-mutate/task-mutate.module';
import { ValidationErrorsModule } from '../validation-errors/validation-errors.module';

@NgModule({
    declarations: [FilterCriteriaComponent, FilterCriteriaListComponent],
    imports: [
        CommonModule,
        NbAutocompleteModule,
        FormsModule,
        NbButtonGroupModule,
        ReactiveFormsModule,
        NbButtonModule,
        NbButtonGroupModule,
        NbInputModule,
        NbSelectModule,
        NbTagModule,
        StateSummaryModule,
        NbCardModule,
        TaskMutateModule,
        NbAccordionModule,
        NbIconModule,
        ButtonModule,
        ValidationErrorsModule,
    ],
    exports: [FilterCriteriaComponent, FilterCriteriaListComponent],
})
export class FilterCriteriaModule {}

import { NgModule } from '@angular/core';
import { StateSummaryComponent } from './state-summary.component';
import { NbTagModule } from '@nebular/theme';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [StateSummaryComponent],
    exports: [StateSummaryComponent],
    imports: [NbTagModule, CommonModule],
})
export class StateSummaryModule {}

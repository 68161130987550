import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisibleAtComponent } from './visible-at.component';
import { NbRadioModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [VisibleAtComponent],
    imports: [CommonModule, NbRadioModule, FormsModule, ReactiveFormsModule],
    exports: [VisibleAtComponent],
})
export class VisibleAtModule {}

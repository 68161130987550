import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionStateComponent } from './selection-state.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NbAccordionModule, NbButtonModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { VisibleAtModule } from '../../components/visible-at/visible-at.module';
import { ValidUntilModule } from '../../components/valid-until/valid-until.module';
import { ButtonModule } from '../../../../components/buttons/buttons.module';
import { DefaultQuestionModule } from '../../components/default-question/default-question.module';
import { DragulaModule } from 'ng2-dragula';
import { ValidationErrorsModule } from '../../../../components/validation-errors/validation-errors.module';

@NgModule({
    declarations: [SelectionStateComponent],
    exports: [SelectionStateComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CommonModule,
        NbCardModule,
        NbInputModule,
        NbAccordionModule,
        NbButtonModule,
        NbIconModule,
        VisibleAtModule,
        ValidUntilModule,
        ButtonModule,
        DefaultQuestionModule,
        DragulaModule,
        ValidationErrorsModule,
    ],
})
export class SelectionStateModule {}

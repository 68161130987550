import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateFilterComponent } from './state-filter.component';
import { SearchSelectModule } from '../../../../components/search/search.module';
import { TaskMutateModule } from '../../../../components/task-mutate/task-mutate.module';
import { NbCardModule, NbSelectModule } from '@nebular/theme';
import { ButtonModule } from '../../../../components/buttons/buttons.module';

@NgModule({
    declarations: [StateFilterComponent],
    imports: [CommonModule, SearchSelectModule, TaskMutateModule, NbCardModule, ButtonModule, NbSelectModule],
    exports: [StateFilterComponent],
})
export class StateFilterModule {}

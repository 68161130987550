import { NgModule } from '@angular/core';
import { XapiCourseZipFileComponent } from './xapi-course-zip-file.component';
import { NbCardModule } from '@nebular/theme';
import { DatePipe, NgIf, NgOptimizedImage } from '@angular/common';

@NgModule({
    declarations: [XapiCourseZipFileComponent],
    imports: [NbCardModule, DatePipe, NgIf, NgOptimizedImage],
    exports: [XapiCourseZipFileComponent],
})
export class XapiCourseZipFileModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownEditorComponent } from './markdown-editor.component';
import { NbButtonModule, NbInputModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownPreviewComponent } from './markdown-preview/markdown-preview.component';

@NgModule({
    declarations: [MarkdownEditorComponent, MarkdownPreviewComponent],
    imports: [CommonModule, NbInputModule, FormsModule, NbButtonModule, ReactiveFormsModule],
    exports: [MarkdownEditorComponent],
})
export class MarkdownEditorModule {}

import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import * as GraphQL from './graphql-generated';
import { environment } from 'src/environments/environment';

const uriAppBackend = environment.URI_APP_BACKEND;
export function createApollo(httpLink: HttpLink): {
    cache: InMemoryCache;
    link: ApolloLink;
    defaultOptions: DefaultOptions;
} {
    const basic = setContext((operation, context) => ({
        headers: {
            Accept: 'application/json',
        },
    }));

    const linkAppBackend = ApolloLink.from([basic, httpLink.create({ uri: uriAppBackend })]);
    const cacheState = new InMemoryCache({
        possibleTypes: GraphQL.default.possibleTypes,
    });

    return {
        link: linkAppBackend,
        cache: cacheState,
        defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MutateStateComponent } from './mutate-state.component';
import {
    NbAutocompleteModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbListModule,
    NbSelectModule,
    NbTagModule,
} from '@nebular/theme';
import { NumericalStateModule } from './numerical-state/numerical-state.module';
import { ReactiveFormsModule } from '@angular/forms';
import { VisibleAtModule } from '../components/visible-at/visible-at.module';
import { SelectionStateModule } from './selection-state/selection-state.module';
import { DescriptionBlockListModule } from '../components/description-block-list/description-block-list.module';
import { ButtonModule } from '../../../components/buttons/buttons.module';
import { DefaultQuestionModule } from '../components/default-question/default-question.module';
import { ValidUntilModule } from '../components/valid-until/valid-until.module';
import { TaskMutateModule } from '../../../components/task-mutate/task-mutate.module';
import { ReferenceModule } from '../../../components/reference/reference.module';
import { SearchSelectModule } from 'src/app/components/search/search.module';
import { MarkdownEditorModule } from '../../../components/markdown-editor/markdown-editor.module';
import { DragulaModule } from 'ng2-dragula';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { ValidationErrorsModule } from '../../../components/validation-errors/validation-errors.module';
import { StateUsageComponent } from '../components/state-usage/state-usage.component';

@NgModule({
    declarations: [MutateStateComponent, CategorySelectorComponent, StateUsageComponent],
    imports: [
        SelectionStateModule,
        CommonModule,
        NbCardModule,
        NbTagModule,
        NbButtonGroupModule,
        NbInputModule,
        NbSelectModule,
        NbButtonModule,
        NumericalStateModule,
        ReactiveFormsModule,
        NbAutocompleteModule,
        VisibleAtModule,
        DescriptionBlockListModule,
        ButtonModule,
        DefaultQuestionModule,
        ValidUntilModule,
        TaskMutateModule,
        SearchSelectModule,
        ReferenceModule,
        MarkdownEditorModule,
        DragulaModule,
        ValidationErrorsModule,
        NbListModule,
    ],
})
export class MutateStateModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NbAccordionModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbOptionModule,
    NbSelectModule,
    NbSidebarModule,
    NbTagModule,
} from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { ProjectCreateComponent } from './project-create.component';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusModule } from 'src/app/directive/focus.module';
import { DragulaModule } from 'ng2-dragula';
import { DistelTextInputModule } from 'src/app/components/distel-text-input/distel-text-input.module';
import { ButtonModule } from '../../components/buttons/buttons.module';
import { ConfirmDialogModule } from '../../components/confirm-dialog/confirm-dialog.module';
import { ProjectsModule } from '../projects/projects.module';
import { StateSummaryModule } from '../../components/state-summary/state-summary.module';
import { MarkdownEditorModule } from '../../components/markdown-editor/markdown-editor.module';
import { SearchSelectModule } from 'src/app/components/search/search.module';
import { FilterCriteriaModule } from '../../components/filter-criteria/filter-criteria.module';
import { StateInputModule } from '../../components/state-input/state-input.module';
import { ValidationErrorsModule } from '../../components/validation-errors/validation-errors.module';

@NgModule({
    declarations: [ProjectCreateComponent],
    imports: [
        DragulaModule,
        FocusModule,
        NbIconModule,
        NbFormFieldModule,
        FormsModule,
        CommonModule,
        RouterModule,
        ConfirmDialogModule,
        NbLayoutModule,
        NbSidebarModule,
        NbButtonModule,
        NbCardModule,
        Angular2SmartTableModule,
        NbCheckboxModule,
        NbListModule,
        NbInputModule,
        NbTagModule,
        DistelTextInputModule,
        ButtonModule,
        ReactiveFormsModule,
        ProjectsModule,
        NbAccordionModule,
        NbAutocompleteModule,
        NbOptionModule,
        NbSelectModule,
        StateSummaryModule,
        SearchSelectModule,
        MarkdownEditorModule,
        FilterCriteriaModule,
        StateInputModule,
        ValidationErrorsModule,
    ],
})
export class ProjectCreateModule {}

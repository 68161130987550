import { NgModule } from '@angular/core';
import { NbAccordionModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from '../../../../components/error/error.module';
import { ButtonModule } from '../../../../components/buttons/buttons.module';
import { CommonModule } from '@angular/common';
import { MarkdownEditorModule } from '../../../../components/markdown-editor/markdown-editor.module';
import { DragulaModule } from 'ng2-dragula';
import { ValidationErrorsModule } from '../../../../components/validation-errors/validation-errors.module';
import { DescriptionBlockListComponent } from './description-block-list.component';
import { DescriptionBlockComponent } from '../description-block/description-block.component';

@NgModule({
    declarations: [DescriptionBlockListComponent, DescriptionBlockComponent],
    imports: [
        NbCardModule,
        NbAccordionModule,
        NbIconModule,
        ReactiveFormsModule,
        NbInputModule,
        ErrorModule,
        ButtonModule,
        CommonModule,
        MarkdownEditorModule,
        DragulaModule,
        ValidationErrorsModule,
    ],
    exports: [DescriptionBlockListComponent, DescriptionBlockComponent],
})
export class DescriptionBlockListModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericalStateComponent } from './numerical-state.component';
import {
    NbAutocompleteModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbSelectModule,
    NbTagModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisibleAtModule } from '../../components/visible-at/visible-at.module';
import { ValidUntilModule } from '../../components/valid-until/valid-until.module';
import { DefaultQuestionModule } from '../../components/default-question/default-question.module';
import { ValidationErrorsModule } from '../../../../components/validation-errors/validation-errors.module';

@NgModule({
    declarations: [NumericalStateComponent],
    exports: [NumericalStateComponent],
    imports: [
        CommonModule,
        NbCardModule,
        NbTagModule,
        NbButtonGroupModule,
        NbInputModule,
        NbSelectModule,
        NbButtonModule,
        ReactiveFormsModule,
        NbAutocompleteModule,
        FormsModule,
        VisibleAtModule,
        ValidUntilModule,
        DefaultQuestionModule,
        ValidationErrorsModule,
    ],
})
export class NumericalStateModule {}

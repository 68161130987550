import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { SearchSelectComponent } from './search-select.component';

@NgModule({
    declarations: [SearchSelectComponent],
    imports: [CommonModule, NbInputModule, FormsModule, NbCardModule, NbIconModule, NbButtonModule],
    exports: [SearchSelectComponent],
})
export class SearchSelectModule {}

import { NgModule } from '@angular/core';
import { SearchComponent } from './search.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NbIconModule } from '@nebular/theme';

@NgModule({
    declarations: [SearchComponent],
    imports: [FormsModule, CommonModule, NbIconModule],
    exports: [SearchComponent],
})
export class SearchSelectModule {}

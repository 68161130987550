import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    NbCardModule,
    NbDialogModule,
    NbLayoutModule,
    NbMenuModule,
    NbSidebarModule,
    NbStatusService,
    NbThemeModule,
    NbThemeService,
    NbToastrModule,
} from '@nebular/theme';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ProjectsModule } from './pages/projects/projects.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { I18nModule } from './core/i18n/i18n.module';
import { StatesModule } from './pages/states/states.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { ThemeModule } from './@theme/theme.module';
import { ProjectCreateModule } from './pages/project-create/project-create.module';
import { FilterCriteriaModule } from './components/filter-criteria/filter-criteria.module';
import { TaskMutateModule } from './components/task-mutate/task-mutate.module';
import { DistelTextInputModule } from './components/distel-text-input/distel-text-input.module';
import { FocusModule } from './directive/focus.module';
import { DragulaModule } from 'ng2-dragula';
import { TasksModule } from './pages/tasks/tasks.module';
import { TaskSelectModule } from './components/task-select/task-select.module';
import { StateInputModule } from './components/state-input/state-input.module';
import { SearchSelectModule } from './components/search-select/search-select.module';
import { ProjectGroupSelectModule } from './components/project-group-select/project-group-select.module';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import { DataTypeAsIconComponent } from './pages/states/components/data-type-as-icon/data-type-as-icon.component';
import { VisibilityAsIconComponent } from './pages/states/components/visibility-as-icon/visibility-as-icon.component';

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: environment.AUTH_SERVER_URL,
                realm: environment.AUTH_REALM,
                clientId: environment.AUTH_CLIENT,
            },
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
            },
        });
}

@NgModule({
    declarations: [AppComponent, DataTypeAsIconComponent, VisibilityAsIconComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NbCardModule,
        NbLayoutModule,
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        BrowserAnimationsModule,
        NbThemeModule.forRoot({ name: 'default' }),
        NbEvaIconsModule,
        ProjectsModule,
        ProjectCreateModule,
        DragulaModule.forRoot(),
        TasksModule,
        FilterCriteriaModule,
        TaskMutateModule,
        FocusModule,
        DistelTextInputModule,
        ConfirmDialogModule,
        GraphQLModule,
        StatesModule,
        TaskSelectModule,
        HttpClientModule,
        KeycloakAngularModule,
        ThemeModule,
        StateInputModule,
        SearchSelectModule,
        ProjectGroupSelectModule,
        NbDialogModule.forRoot({
            hasBackdrop: true,
        }),
        NbToastrModule.forRoot({ duration: 5_000 }),
    ],
    providers: [
        NbStatusService,
        NbThemeService,
        I18nModule.setLocale(),
        I18nModule.setLocaleId(),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
    constructor(private keycloak: KeycloakService) {}

    ngOnInit(): void {
        this.logout();
    }

    logout() {
        this.keycloak.logout(location.origin).then();
    }
}

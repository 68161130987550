import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultQuestionComponent } from './default-question.component';
import { NbInputModule } from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorsModule } from '../../../../components/validation-errors/validation-errors.module';

@NgModule({
    declarations: [DefaultQuestionComponent],
    imports: [NbInputModule, CommonModule, ReactiveFormsModule, ValidationErrorsModule],
    exports: [DefaultQuestionComponent],
})
export class DefaultQuestionModule {}

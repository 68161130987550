import { NgModule } from '@angular/core';
import { CloseButtonComponent } from './close-button/close-button.component';
import { PlusButtonComponent } from './plus-button/plus-button.component';
import { SvgModule } from '../svg/svgs.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReplaceButtonComponent } from './replace-button/replace-button.component';
import { ButtonGroupSelectComponent } from './button-group-select/button-group-select.component';
import { NbButtonGroupModule } from '@nebular/theme';
import { ButtonGroupMultiSelectComponent } from './button-group-select/button-group-multi-select/button-group-multi-select.component';

@NgModule({
    declarations: [
        CloseButtonComponent,
        PlusButtonComponent,
        ReplaceButtonComponent,
        ButtonGroupSelectComponent,
        ButtonGroupMultiSelectComponent,
    ],
    exports: [
        CloseButtonComponent,
        PlusButtonComponent,
        ReplaceButtonComponent,
        ButtonGroupSelectComponent,
        ButtonGroupMultiSelectComponent,
    ],
    imports: [SvgModule, CommonModule, NgOptimizedImage, NbButtonGroupModule],
})
export class ButtonModule {}
